import React from "react";
import * as PropTypes from "prop-types";
import TagList from "../components/TagList";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/SEO/Seo";
import Content, { HTMLContent } from "../components/Content";
import Features from "../components/Features";
import { FaRegNewspaper } from "react-icons/fa";

const ArtworkIntroTemplate = ({
  title,
  content,
  contentComponent,
  intro,
  heading,
  tags,
  langKey,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div className="container content">
      <h1 className="title animated bounceInLeft">{title}</h1>
      <div className="hero">
        <Features gridItems={intro.blurbs} />
      </div>
      <h2 className="has-text-weight-semibold subtitle">
        <FaRegNewspaper className="menu-names" color="#D64000" />
        {heading}
      </h2>
      <div className="container">
        <section className="section">
          <PageContent className="container content" content={content} />
        </section>
      </div>
      <TagList tags={tags} langKey={langKey} />
    </div>
  );
};

ArtworkIntroTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  tags: PropTypes.array,
  langKey: PropTypes.string,
};

class ArtworksIntroPage extends React.Component {
  render() {
    const data = this.props.data;
    const { frontmatter } = data.markdownRemark;
    const jsonData = data.allArticlesJson.edges[0].node.articles;
    const image = frontmatter.image.childImageSharp.gatsbyImageData.src;
    const langKey = frontmatter.lang;
    const tags = frontmatter.tags;
    return (
      <Layout
        className="container"
        data={data}
        jsonData={jsonData}
        location={this.props.location}
      >
        <Seo frontmatter={frontmatter} postImage={image} />
        <div>
          <ArtworkIntroTemplate
            contentComponent={HTMLContent}
            heading={frontmatter.heading}
            title={frontmatter.title}
            content={data.markdownRemark.html}
            intro={frontmatter.intro}
            tags={tags}
            langKey={langKey}
          />
        </div>
      </Layout>
    );
  }
}

ArtworksIntroPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ArtworksIntroPage;

export const pageQuery = graphql`
  query ArtworksIntroQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            it
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        id
        title
        lang
        tags
        image {
          childImageSharp {
            gatsbyImageData(quality: 87, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            heading
            link
            text
          }
        }
      }
    }
  }
`;
